// extracted by mini-css-extract-plugin
export var background_images = "dottedFrame-module__background_images___HJ5f-";
export var body_background = "#f8f9fa";
export var card = "dottedFrame-module__card___sLMlI";
export var chip = "dottedFrame-module__chip___LCK9s";
export var container = "dottedFrame-module__container___qCQhO";
export var content = "dottedFrame-module__content___+gH4G";
export var dotted_frame = "dottedFrame-module__dotted_frame___3be62";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "dottedFrame-module__full_img___5QV0+";
export var full_imgSmall = "dottedFrame-module__full_img--small___PzcZc";
export var gray_21 = "dottedFrame-module__gray_21___xbNtG";
export var image_container = "dottedFrame-module__image_container___crVXw";
export var image_item = "dottedFrame-module__image_item___sS7zX";
export var lg = "1200px";
export var logo = "dottedFrame-module__logo___wWD6a";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "dottedFrame-module__primary___MC845";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "dottedFrame-module__title___HaI-C";
export var typography_h1 = "dottedFrame-module__typography_h1___2ywmC";
export var typography_h2 = "dottedFrame-module__typography_h2___RhYzz";
export var typography_h3 = "dottedFrame-module__typography_h3___Xwoof";
export var white = "#fff";
export var white_button = "dottedFrame-module__white_button___g8R1u";
export var xl = "1536px";
export var xxl = "2500px";